import React from 'react'
import GatsbyImage from 'gatsby-image'
import SimpleReactLightbox from 'simple-react-lightbox-pro'
import { SRLWrapper } from "simple-react-lightbox-pro"

class Photo extends React.Component {
    render() {
        const photoData = this.props.data
        const description = photoData.description
        const showCaption = this.props.caption
        const aspectRatio = photoData.fluid.aspectRatio

        let orientation;
        if (aspectRatio >= 1.2) orientation = "figure-image-orientation--landscape";
        if (aspectRatio <= 0.8) orientation = "figure-image-orientation--portrait";
        if (aspectRatio > 0.8 && aspectRatio < 1.2) orientation = "figure-image-orientation--square";

        const srloptions = {
            settings: {
                disablePanzoom: true
            },
            thumbnails: {
                showThumbnails: false,
            },
            buttons: {
                showNextButton: false,
                showPrevButton: false,
                showAutoplayButton: false,
                showThumbnailsButton: false,
                showDownloadButton: false,
                showFullscreenButton: false
            }
        }

        return (
            <SimpleReactLightbox>
                <SRLWrapper options={srloptions}>
                    <figure className={`figure ${orientation}`}>
                        <a href={photoData.fixed.src} className={`figure__link`}>
                            {photoData ? (
                                <GatsbyImage fluid={photoData.fluid} alt={description} />
                            ) : ""}
                        </a>
                        {showCaption != "hide" ? (
                            <figcaption className="figure__caption">{description}</figcaption>
                        ) : ""}
                    </figure>
                </SRLWrapper>
            </SimpleReactLightbox>
        )
    }
}

export default Photo
